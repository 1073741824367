import {State, Action, StateContext, Selector} from "@ngxs/store";
import{TitleAction} from "@actions/title.action";

@State<any>({
  name: "TitleStates",
  defaults: {
    data: null
  }
})
export class TitleState {
  @Selector()
  static getData(state: any) {
    return state.data
  }

  @Action(TitleAction.SetTitleState)
  setData(ctx : StateContext<any>,action : TitleAction.SetTitleState){
    ctx.patchState({data : action.title})
  }
}
