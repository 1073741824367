import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  // Returns current window object
  get window(): any {
    return _window();
  }

// Used in calendar component
// @returns document's height and width whenever window is resized
  onResize() {
    return fromEvent(window, 'resize').pipe(
      map(() => {
        return {
          width: this.window.document.documentElement.clientWidth,
          height: this.window.document.documentElement.clientHeight,
        };
      }),
      debounceTime(200)
    );
  }
}
