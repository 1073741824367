export namespace UserAction {
    // action for storing token

    export class SetUserToken {
        static readonly type = "[SetUserToken] Set User Access Token";

        constructor(public accesstoken: string) {
        }
    }

}
