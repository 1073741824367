import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {IsAuthenticated, IsNotAuthenticated} from "@core/guards";
import {NoPageComponent} from "@shared/components"

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationPageModule),
    canMatch: [IsNotAuthenticated]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canMatch: [IsAuthenticated]
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'archive',
    redirectTo: 'offer/campaign',
    pathMatch: 'full',
    canMatch: [IsAuthenticated]
  },
  {
    path: 'login',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarPageModule),
    canMatch: [IsAuthenticated]
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
    canMatch: [IsAuthenticated]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    canMatch: [IsAuthenticated]
  },
  {
    path: 'offer',
    loadChildren: () => import('./features/offer/offer.module').then(m => m.OfferModule),
    canMatch: [IsAuthenticated]
  },
  {
    path: 'profi',
    loadChildren: () => import('./features/jatak/jatak.module').then(m => m.JatakModule),
    canMatch: [IsAuthenticated]
  },
  {
    path: '**',
    redirectTo: "/404",
    pathMatch: "full"
  },
  {
    path: "404",
    component: NoPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
