import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'profi-search-template-skeleton',
  templateUrl: './search-template-skeleton.component.html',
  styleUrls: ['./search-template-skeleton.component.scss'],
})
export class SearchTemplateSkeletonComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
