import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from 'rxjs';
import {environment} from "@env/environment";
import {ToastService} from './toast.service';


@Injectable({
  providedIn: 'root'
})

export class ErrorHandlerService {
  constructor(
    private toastService: ToastService,
    private _translate : TranslateService
  ) {
  }

  handleError(error: any, errorOf?: any): void {

    if (error.error.server) {
      this.toastService.presentToast(error.error.message, 'error').then()
      return;
    }

    this.toastService.presentToast(this._translate.instant('_something_went_wrong_'), 'error').then()
  }

  routeResolverError(error: Error): Observable<any> {
    this.handleError(error, '');
    this.toastService.presentToast(this._translate.instant('_something_went_wrong_'), 'error').then()
    return of(error);
  }

}
