import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {take} from "rxjs";
import {FolderData, TemplateData} from "@shared/interface/templates.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {TemplatesService} from "../../../../features/offer/services/templates.service";
import {ErrorHandlerService} from "@core/services/error-handler.service";
import th from "@mobiscroll/angular/dist/js/i18n/th";
import {environment} from "@env/environment";

@Component({
  selector: 'profi-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() showRecentlyused: boolean = false
  @ViewChild('searchBox') searchBox: ElementRef;
  recentlyUsedTemplates: TemplateData[] = [];
  resultTemplates: TemplateData[] = [];
  resultTemplatesPage:number;
  showResults: boolean = false
  resultFolder: FolderData[] = []
  imageLoaded = false;
  isInputfieldClicked = false;
  isSearchClicked = false;
  doesTypeincludeNews = false;
  doesTypeinlcudeStoreSpecial = false;
  doesTypeincludeOffer = false;
  isNewsClicked: boolean = false;
  isStoreSpecialClicked: boolean = false;
  isOfferClicked: boolean = false;
  loading = true;
  filterby: string = '';
  page:number=1;
  templatePage:number=1;
  types: any = []
  showOfferOption = false;
  placeholderImageUrl = './assets/images/image89.svg'
  searchString: string;
  disabledFilter: boolean = false
  typeList: any = []
  environment = environment;
  @Input() searchFolderTemplate: boolean = false
  @Output() resultFolderEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private templateService: TemplatesService,
              private errorHandlerService: ErrorHandlerService,
              private router: Router) {
  }

  ngOnInit() {
    // this.getRecentlyUsedTemplates()
  }

  getRecentlyUsedTemplates() {
    this.templateService.getRecentlyUsed()
      .pipe(
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.recentlyUsedTemplates = res;
          this.loading = false
        }
      })
  }

  onImageLoad($event: Event) {
    this.imageLoaded = true;
  }

  recentTemplateSelect(id: number, type: string) {
    this.router.navigate(['/offer/template/' + type + '/' + id], {replaceUrl: true}).then()
  }

  onInputClicked() {
    this.isInputfieldClicked = true
  }

  hideSearchResult() {
    this.searchBox.nativeElement.value = null;
    this.searchString = ''
    this.showResults = false
    this.isSearchClicked = false
    this.showOfferOption = false
    this.resultTemplates = []
    this.templatePage = 1;
    if (this.searchFolderTemplate) {
      this.getFolderList()
    }
  }

  hideRecentlyUsed() {

    this.isInputfieldClicked = false
  }

  // onClickOutside() {
  //   this.isInputfieldClicked = false
  // }

  search(searchInput: string) {
    this.isSearchClicked = true;
    this.isInputfieldClicked = false
    this.searchString = searchInput;
    if (searchInput !== '' && searchInput.trim().length !== 0) {
      this.loading = true;
      this.resultTemplates = []
      if (this.searchFolderTemplate) {
        this.getSearchFolderList(this.searchString)
      } else {
        this.getSearchTemplateList(this.searchString)
      }
    }
  }

loadMore(event:any){
    event.stopPropagation();
    this.templatePage++;
    this.isSearchClicked = true;
  if (this.searchFolderTemplate) {
    this.getSearchFolderList(this.searchString)
  } else {
    this.getSearchTemplateList(this.searchString)
  }
}
  private getSearchTemplateList(searchInput: string) {
    this.templateService.searchTemplateList(searchInput, this.typeList.join(','), this.templatePage)
      .pipe(
        take(1)
      ).subscribe({
      next: (res) => {
        this.showResults = true
        this.resultTemplates = this.resultTemplates.concat(res['data']['templates'])
        this.resultTemplatesPage= res['data']['meta']['last_page']
        this.types = res['data']['types'];
        this.handleFilterStates()
        this.loading = false;
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  private getSearchFolderList(searchInput: string) {
    this.templateService.searchFolderList(searchInput, this.typeList.join(','),this.templatePage).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.showResults = true
        this.resultTemplates = this.resultTemplates.concat(res['data']['templates']['data'])
        this.resultTemplatesPage = res['data']['templates']['meta']['last_page']
        this.resultFolder = res['data']['folders']['data']
        this.types = res['data']['templates']['types'];
        this.handleFilterStates()
        if (this.resultFolder.length) {
          this.resultFolderEmitter.emit(this.resultFolder)
        }
        this.loading = false;
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  closeSearch() {
    this.searchBox.nativeElement.value = null;
    this.searchString = ''
    this.showResults = false
    this.isSearchClicked = false
    this.resultTemplates = []
    this.templatePage=1;
    if (this.searchFolderTemplate) {
      this.getFolderList()
    }
  }


  private getFolderList() {
    this.templateService.getFolders(this.page)
      .pipe(
        take(1)
      ).subscribe({
      next: (res) => {
        this.resultFolder = res['data']['folders']
        this.resultFolderEmitter.emit(this.resultFolder)
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }


  private handleFilterStates() {
    this.isNewsClicked = this.types.includes('news');
    this.isStoreSpecialClicked = this.types.includes('store_special');
    this.isOfferClicked = this.types.includes('offer');
    this.doesTypeincludeNews = this.isNewsClicked;
    this.doesTypeinlcudeStoreSpecial = this.isStoreSpecialClicked;
    this.doesTypeincludeOffer = this.isOfferClicked;
    if (this.types.length === 1) {
      this.disabledFilter = true
    }
  }

  private getFilteredList() {
    this.templateService.searchTemplateList(this.searchString, this.typeList.join(','),this.templatePage).pipe(
      take(1)
    ).subscribe({
      next: (res) => {
        this.resultTemplates = res['data']['templates']
        this.doesTypeinlcudeStoreSpecial = this.types.includes('store_special')
        this.doesTypeincludeOffer = this.types.includes('offer')
        this.doesTypeincludeNews = this.types.includes('news')
        this.loading = false;

      }
    })
  }

  toggleselectType() {
    this.showOfferOption = !this.showOfferOption;
  }

  toggleTypeSelect(type: string, event: any) {
    event.stopPropagation();
    if (this.types.length === 1) {
      return;
    } else {
      let difference = this.types.filter((x: string) => !this.typeList.includes(x));
      if (difference.length <= 1 && !this.typeList.includes(type)) {
        return;
      }
    }
    if (type === 'news') {
      this.filterby = 'news'
      if (this.doesTypeincludeNews) {
        this.isNewsClicked = !this.isNewsClicked
        if (!this.isNewsClicked) {

          this.typeList.push('news')
        } else {
          this.typeList.pop('news')
        }
        // this.getFilteredList()
      }
    }
    if (type === 'offer') {
      this.filterby = 'offer'

      if (this.doesTypeincludeOffer) {
        this.isOfferClicked = !this.isOfferClicked
        if (!this.isOfferClicked) {
          this.typeList.push('offer')
        } else {
          this.typeList.pop('offer')

        }
        // this.getFilteredList()
      }
    }
    if (type === 'store_special') {
      this.filterby = 'store_special'
      if (this.doesTypeinlcudeStoreSpecial) {
        this.isStoreSpecialClicked = !this.isStoreSpecialClicked
        if (!this.isStoreSpecialClicked) {
          this.typeList.push('store_special')
        } else {
          this.typeList.pop('store_special')
        }

      }
    }
    this.getFilteredList()


  }


  private handleError(error: Error) {
    this.errorHandlerService.handleError(error, '')
  }
}

