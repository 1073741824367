import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxsModule} from "@ngxs/store";
import {environment} from "@env/environment";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {AppState, FormStates} from "@states/states";
import {register} from "swiper/element/bundle";
import {IonicStorageModule} from "@ionic/storage-angular";
import {RequestInterceptorService} from "./services/http-interceptor.service";
import {UserDetails} from "@states/user.state";
import {RouteStates} from "@states/route.state";
import {TabState} from "@states/tab.state";
import {CampaignState} from "@states/campaign.state";
import {TitleState} from "@states/title.state";

register();

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AppState, UserDetails, FormStates, RouteStates, TabState, CampaignState, TitleState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    IonicStorageModule.forRoot(),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true
  }]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}


