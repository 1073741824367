<div>
  <div class="searchsection">
    <div class="searchinput">
      <input type="search"
             [placeholder]="(searchFolderTemplate ? '_search_from_template_folder_' : '_create_from_template_') | translate"
             #searchBox
             (focusin)="onInputClicked()"
             (keyup.enter)="search(searchBox.value)"
             class="search-input"
      />
      <div *ngIf="searchString" (click)="closeSearch()" class="close-search">
        <ion-icon name="close-circle-outline"></ion-icon>
      </div>
    </div>
    <div (click)="search(searchBox.value)" class="searchicon">
      <ion-icon name="search-outline"></ion-icon>
    </div>
  </div>

  <!-- recently used section-->

  <div>
    <div *ngIf=" showRecentlyused && recentlyUsedTemplates.length && isInputfieldClicked " class="template-list">
      <div class="recently-used-section">{{'_recently_used_' |translate}}</div>
      <div>
        <div *ngFor="let recentlyUsedTemplate of recentlyUsedTemplates;let last= last">
          <div (click)="recentTemplateSelect(recentlyUsedTemplate.id, recentlyUsedTemplate.type)" class="result-list">
            <div style="display: flex">
              <img
                (load)="onImageLoad($event)"
                [src]="imageLoaded ? recentlyUsedTemplate.image : placeholderImageUrl"
                class="result-img"
              />
              <div class="result-content">
                <span *ngIf=" recentlyUsedTemplate.type === 'store_special'"
                      class="result-title">{{"_store_special_" | translate}}</span>
                <span *ngIf="recentlyUsedTemplate.type === 'news' " class="result-title">{{"_news_" | translate}}</span>
                <span *ngIf="recentlyUsedTemplate.type === 'offer' "
                      class="result-title">{{"_offer_" | translate}}</span>
                <p class="result-subtitle">{{recentlyUsedTemplate.title}}</p>
              </div>
            </div>
            <div style="width:24px">
              <ion-icon name="chevron-forward-outline" class="result-right-icon"></ion-icon>
            </div>
          </div>
          <div *ngIf="!last" class="result-separator"></div>
        </div>
      </div>
    </div>
  </div>


  <!--<div *ngIf="loading && isSearchClicked"></div>-->
  <div *ngIf="isSearchClicked && loading">
    <profi-search-template-skeleton></profi-search-template-skeleton>
  </div>
  <!--<div clickOutside (clickOutside)="hideSearchResult()">-->
  <div *ngIf="isSearchClicked && resultTemplates.length>0 && !loading" class="template-list">
    <div style="border-bottom: 1px solid #C5C5C7">
      <div class="result-title-content">
        <p>{{resultTemplates.length}} {{'_results_' |translate}}</p>
        <ion-icon name="filter-outline" style="font-size: 24px;cursor: pointer" (click)="toggleselectType()"></ion-icon>
      </div>
    </div>
    <div *ngIf="showOfferOption" class="current-section">
      <p class="current-in-view">{{"_currently_in_view_" | translate}}:</p>
      <div class="offer-container">
        <div class="offer-type"
             [class.disabled]="disabledFilter"
             (click)="doesTypeinlcudeStoreSpecial ? toggleTypeSelect('store_special',$event) : null">
          <ion-icon *ngIf="!isStoreSpecialClicked" class="check-icon" name="square-outline"></ion-icon>
          <ion-icon *ngIf="isStoreSpecialClicked " class="check-icon" name="checkbox"></ion-icon>
          <p class="offer-type-text">{{"_filter_store_special_" | translate}}</p>
        </div>
        <div
          class="offer-type"
          [class.disabled]="disabledFilter"
          (click)="doesTypeincludeNews ? toggleTypeSelect('news',$event) : null">
          <ion-icon *ngIf="!isNewsClicked" class="check-icon" name="square-outline"></ion-icon>
          <ion-icon *ngIf="isNewsClicked" class="check-icon" name="checkbox"></ion-icon>
          <p class="offer-type-text">{{"_filter_news_" | translate}}</p>
        </div>
        <div class="offer-type"
             [class.disabled]="disabledFilter"
             (click)="doesTypeincludeOffer ? toggleTypeSelect('offer',$event): null">
          <ion-icon *ngIf="!isOfferClicked" class="check-icon" name="square-outline"></ion-icon>
          <ion-icon *ngIf="isOfferClicked" class="check-icon" name="checkbox"></ion-icon>
          <p class="offer-type-text">{{"_filter_offer_" | translate}}</p>
        </div>
      </div>
    </div>
    <div *ngIf="resultTemplates.length === 0 ">
      <p class="empty-data">{{'_no_templates_found_' | translate}}</p>
    </div>
    <!--<div>-->
    <div>
      <div *ngIf="resultTemplates.length !== 0 && !loading">
        <div *ngFor="let resultTemplate of resultTemplates;let last= last">
          <div (click)="recentTemplateSelect(resultTemplate.id, resultTemplate.type)" class="result-list">
            <div style="display: flex">
              <img
                (load)="onImageLoad($event)"
                [src]="imageLoaded ? resultTemplate.image : placeholderImageUrl"
                class="result-img"
              />
              <div class="result-content">
                <span *ngIf="resultTemplate.type === 'store_special'"
                      class="result-title">{{ '_store_special_' |translate }}</span>
                <span *ngIf="resultTemplate.type === 'news' " class="result-title">{{ "_news_" |translate }}</span>
                <span *ngIf="resultTemplate.type === 'offer' " class="result-title">{{ '_offer_' | translate }}</span>

                <p class="result-subtitle">{{resultTemplate.title}}</p>
              </div>
            </div>
            <div style="width: 24px">
              <ion-icon name="chevron-forward-outline" class="result-right-icon"></ion-icon>
            </div>
          </div>
          <div *ngIf="!last" class="result-separator"></div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="(resultTemplatesPage > templatePage) && resultTemplates.length > 0">
    <div (click)="loadMore($event)" class="loadmoreBtn">
      <p>{{"_load_more_" |translate}}</p>
    </div>
  </div>
  <div *ngIf="isSearchClicked && resultTemplates.length === 0 && !loading" class="template-list">
    <div style="border-bottom: 1px solid #C5C5C7">
      <div class="result-title-content">
        <p>{{resultTemplates.length}} {{'_results_' |translate}}</p>
        <ion-icon name="filter-outline" style="font-size: 24px"></ion-icon>
      </div>
    </div>
    <div>
      <p class="empty-data">{{"_no_templates_found_" |translate}}</p>
    </div>
  </div>
  <!--</div>-->

</div>
