import {State, Action, StateContext, Selector} from "@ngxs/store";
import { CampaignAction } from "@actions/campaign.action";
import {TabAction} from "@actions/tab.action";
import de from "@mobiscroll/angular/dist/js/i18n/de";

@State<any>({
  name: "CampaignStates",
  defaults: {
    data: null
  }
})

export class CampaignState {
  @Selector()
  static getData(state  :any) {
    return state.data
  }

  @Action(CampaignAction.SetCampaignState)
  setData(ctx : StateContext<any>,action : CampaignAction.SetCampaignState){
    ctx.patchState({data : action.tab})
  }

  @Action(CampaignAction.ClearCampaignState)
  clearData(ctx : StateContext<any>) {
    const defaults = {
      data : null
    }
    ctx.setState(defaults)
  }
}
