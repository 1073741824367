import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Offer} from "../../../../pages/calendar/interface/calendarevents.interface";
import {OfferServices} from "@shared/services/offer.services";
import {AppeventsService, LangService, ToastService} from "@core/services";
import {ErrorHandlerService} from "@core/services/error-handler.service";
import th from "@mobiscroll/angular/dist/js/i18n/th";
import * as dayjs from "dayjs";
import 'dayjs/locale/ro.js'
import {TokenService} from "@core/services/token.service";
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'profi-offerpreview',
  templateUrl: './offerpreview.component.html',
  styleUrls: ['./offerpreview.component.scss'],
})
export class OfferpreviewComponent implements OnInit {
  showOfferDetails: boolean = true
  promoOffer: Offer
  lang: string
  timezone: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _services: OfferServices,
    private _toastService: ToastService,
    private _errorServices: ErrorHandlerService,
    private _langService: LangService,
    private _tokenService: TokenService,
    private alertCtrl: AlertController,
    private _translate: TranslateService,
    private _appEvent : AppeventsService
  ) {
  }

  ngOnInit() {
    this.initComp().then()
  }

  private async initComp() {
    this.promoOffer = this._activatedRoute.snapshot.data['offer']
    let userInfo = await this._tokenService.getUserInfo();
    this.timezone = userInfo.timezone
    this._langService.getLang().then((res) => {
      this.lang = res
    })
  }

  onClose() {
    this.showOfferDetails = false
    this._router.navigate(['/calendar'], {replaceUrl: true}).then(() => {
    } )
  }

  onPreviewclick(event: Event) {
    this.showOfferDetails = true;
    event.stopPropagation();

  }

  onEdit(id: number, type: string) {
    this._router.navigate(['/offer/edit/' + type + '/' + id], {replaceUrl: true}).then()
  }

  async onDelete(type: string, id: number) {
    const alert = await this.alertCtrl.create({
      mode: 'ios',
      message: this._translate.instant('_are_you_sure_'),
      buttons: [
        {
          text: this._translate.instant('_cancel_'),
          role: 'cancel',
        },
        {
          text: this._translate.instant('_delete_'),
          cssClass: 'buttonCss',
          handler: () => this.confirmdelete(type, id)
        },
      ],
    });
    await alert.present();

  }

  confirmdelete(type: string, id: number) {
    if (type === 'store_special') {
      this._services.deleteStoreSpecial(id).subscribe({
        next: (res) => {
          this._toastService.presentToast(this._translate.instant('_deleted_successfully_'), 'success').then(() => {
            this._router.navigate(['/calendar'], {replaceUrl: true}).then()
          })
        },
        error: (err) => {
          this.handleError(err)
        }
      })
    } else {
      this._services.deleteNewsOffer(id).subscribe({
        next: (res) => {
          this._toastService.presentToast(this._translate.instant('_deleted_successfully_'), 'success').then(() => {
            this._router.navigate(['/calendar']).then(() => {
              this._appEvent.setCalendar(true)
            } )
          })
        },
        error: (err) => {
          this.handleError(err)
        }
      })
    }
  }

  formatDatetime(data: string) {
    dayjs.locale(this.lang)
    return dayjs.utc(data).tz(this.timezone).format('DD. MMM YYYY, HH:mm');
  }

  handleError(err: Error) {
    this._errorServices.handleError(err, '')
  }

}
