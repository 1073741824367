import {Component, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {themes} from "@mobiscroll/angular";

@Component({
  selector: 'profi-language-popover',
  templateUrl: './language-popover.component.html',
  styleUrls: ['./language-popover.component.scss'],
})
export class LanguagePopoverComponent implements OnInit {
  languageList = ['ro', 'en'];
  currentLanguage = 'en';

  constructor(
    private popoverCtrl: PopoverController
  ) {
  }

  ngOnInit() {
  }


  onSelect(dest: any) {
    if (!dest) {
      return;
    }
    this.currentLanguage = dest;
    this.dismissPopover(dest).then();
  }

  private async dismissPopover(selection: string) {
    await this.popoverCtrl.dismiss(selection);
  }

}
