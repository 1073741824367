export namespace TitleAction {
  export class SetTitleState {
    static readonly type = "[SetTitleState] Set Title Data"

    constructor(public title: any) {
    }
  }
  export class ClearTitle {
    static readonly type = "[ClearTitleState] Clear Title Data"
  }
}
