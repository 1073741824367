import {Injectable} from '@angular/core';
import {ApiService} from "./index";
import {Store} from "@ngxs/store";
import {map} from "rxjs/operators";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService,
              private store: Store
  ) {
  }


  getUserDetails() {
    const url = '/detail'
    return this.apiService.get(url).pipe(map(res => res['data']))
  }

  autoLogin(code: any, redirect: string) {
    let url;
    if (redirect == 'unpublished') {
      const redirectUrl = environment.baseUrl + '/offer/unpublished'
      url = `/store-auth-verify?token=${code}&redirect=${redirectUrl}`
    } else if (redirect == 'archive') {
      const redirectUrl = environment.baseUrl + '/archive'
      url = `/store-auth-verify?token=${code}&redirect=${redirectUrl}`
    } else {
      url = `/store-auth-verify?token=${code}`
    }

    return this.apiService.get(url).pipe(map(res => res['data']))
  }

}
