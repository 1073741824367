import {Component, OnInit, Renderer2} from '@angular/core';
import {FacebookeventsService, FacebookService} from "../../services";
import {Observable, take, takeWhile} from "rxjs";
import {Select, Store} from "@ngxs/store";
import {AppState} from "@states/app.state";
import {AppStates} from "@interfaces/app.interface";
import {ModalController} from "@ionic/angular";
import {FaceBookPage} from "../../interface/facebookconnect.interface";
import {Router} from "@angular/router";
import {environment} from "@env/environment";
import {TranslateService} from "@ngx-translate/core";
import {logoGithub} from "ionicons/icons";
import {ToastService} from "@core/services";
import {ErrorHandlerService} from "@core/services/error-handler.service";
import {AppActions} from "@actions/app.action";

@Component({
  selector: 'facebook-connect-modal',
  templateUrl: './facebookmodal.component.html',
  styleUrls: ['./facebookmodal.component.scss'],
})
export class FacebookmodalComponent implements OnInit {
  @Select(AppState) $appState: Observable<AppStates> | undefined
  title = '_click_on_page_to_connect_';
  fbDialogURL = '';
  showLogin = false;
  pageResponse = true;
  pages: FaceBookPage[] = [];
  child: Window = window;
  prevList = '';
  nextList = '';
  imageLoaded = false;
  private subscriptionState = true;

  fbPage = {
    "name": "SMM - Graph Test",
    "picture": {
      "data": {
        "height": 200,
        "is_silhouette": false,
        "url": "https://scontent.fjkr2-1.fna.fbcdn.net/v/t39.30808-1/308624495_530487752410561_6107287735243990171_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=9e7101&_nc_ohc=otBkghZScS4AX-EYXqb&_nc_ht=scontent.fjkr2-1.fna&edm=AGaHXAAEAAAA&oh=00_AfCrUP44ennTpC8fK_9qoLWATdkL0uLscIlRcQpm6dZsLQ&oe=656D73B2",
        "width": 200
      }
    },
    "access_token": "EAAVup9O9nKYBOyxYXdDBcjVLFAA6vKrJBPyhL9VAUDxuknfq1Dn8tZCdYmvz2d36P78pZCljQOSsTKvo0jxuTPOrwQ5KQeIRLCvsXtDoLgGeWzkEofCF7iQ6ZAAJpFnq96ezRCQsPUxGIhE4yTb4M3LVJblQzGo4WnIHMiXqKb8wYRIGCExzRxdB8rwoRIZD",
    "id": "112335530110636"
  }

  constructor(
    private modalCtrl: ModalController,
    private renderer: Renderer2,
    private facebookService: FacebookService,
    private router: Router,
    private facebookEvents: FacebookeventsService,
    private _translate: TranslateService,
    private _toastService: ToastService,
    private _store: Store,
    private _errorHandlerService: ErrorHandlerService
  ) {

  }

  ngOnInit() {
    this.child = window
    this.renderer.listen('window', 'message', (event) => {
      this.receiveFacebookToken(event);
    });
    this.router.events
      .pipe(takeWhile(() => this.subscriptionState))
      .subscribe(() => this.closeFbLogin());

    this.$appState?.subscribe({
      next: (state) => {
        this.fbDialogURL = state.facebookDialogBoxUrl;
        if (this.fbDialogURL) {
          this.showLogin = true;
        }
      }
    })
    // this.facebookEvents.connectFacebook()
  }

  private receiveFacebookToken(e: MessageEvent) {
    if (e.origin !== environment.mainUrl || e.data.source !== 'FB_LOGIN' || e.data.error === 'CANNOT_CONNECT_TO_FACEBOOK') {
      return;
    }
    this.facebookService.getPageList(e.data.token).subscribe({
      next: (fbResponse: any) => {
        if (fbResponse.data.length === 0) {
          this.pageResponse = false;
          this.title = this._translate.instant('_no_page_linked_');
        } else {
          this.title = this._translate.instant('_click_on_page_to_connect_');
          this.pageResponse = true;
          this.loadMorePages(fbResponse);
        }
      }
    })
  }

  async dismissModal() {
    await this.modalCtrl.dismiss({});
  }

  selectPage(page: FaceBookPage) {
    this.closeFbLogin()
    this.facebookService.connectPage(page.name, page.id, page.access_token, page.picture.data.url).subscribe({
      next: (res) => {
        this._toastService.presentToast(this._translate.instant('_fb_connected_'), 'success').then(() => {
          this.dismissModal().then()
        })
        this.facebookEvents.facebookConnected(true)
        this._store.dispatch(new AppActions.SetFacebookDialogBoxUrl(''))
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  pageLogin() {
    if (this.fbDialogURL) {
      this.child.open(
        this.fbDialogURL,
        'Facebook Login',
        'width=600, height=420'
      )
    }
  }

  closeFbLogin() {
    this.showLogin = false;
  }

  loadMorePages(fbResponse: any) {
    this.pages = fbResponse['data']
    this.nextList = fbResponse['paging']['next'];
    this.prevList = fbResponse['paging']['previous'];
  }

  loadPageList(type: string) {
    const url = type === 'prev' ? this.prevList : this.nextList;
    this.facebookService.getPagination(url).pipe(
      take(1)
    ).subscribe({
      next: (fbResponse) => {
        this.loadMorePages(fbResponse)
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  private handleError(err: any) {
    this._toastService.presentToast(err.error.message, 'error').then(() => {
      this.dismissModal().then()
    })
  }
}
