import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppeventsService {

  constructor() {
  }

  private _triggerCalendar = new Subject<boolean>()
  $calendar = this._triggerCalendar.asObservable()


  setCalendar(state: boolean) {
    this._triggerCalendar.next(state)
  }


}
