import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FacebookmodalComponent} from "./components";
import {TranslateModule} from "@ngx-translate/core";
import {IonicModule} from "@ionic/angular";


@NgModule({
    declarations: [
        FacebookmodalComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        IonicModule
    ],
    exports: [
        FacebookmodalComponent
    ]
})
export class FacebookconnectModule {
}
