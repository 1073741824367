import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'profi-no-page',
  templateUrl: './no-page.component.html',
  styleUrls: ['./no-page.component.scss'],
})
export class NoPageComponent  implements OnInit {

  constructor(private router : Router) { }

  ngOnInit() {

  }
  onGoBack(){
    this.router.navigateByUrl('/dashboard',{replaceUrl: true})
  }

}
