import {State, Action, StateContext, Selector} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {FormAction} from "@actions/form.action";
import {Channels} from "../../features/offer/interface/offer.interface";

export interface formData {
  id?: number
  template_id: any
  type: string
  images: []
  title: string
  text: string
  channels: Channels
  price: any
  quantity: any
  price_description: any
  reply_text: any
  sold_out_reply: any
  sold_out_title: any
  limit_per_customer: any
  max_customer_order: any
  approved_order: any
  approved_quantity: any
  start_now: boolean
  publish_date: string
  expiration_date: string
  phone?: string
}

export interface formDataState {
  data: formData
}

@State<any>({
  name: "FormStates",
  defaults: {
    data: null
  }
})

export class FormStates {
  @Selector()
  static getData(state: any) {
    return state.data
  }

  @Action(FormAction.SetFormValue)
  setData(ctx: StateContext<any>, action: FormAction.SetFormValue) {
    ctx.patchState({data: action.data})
  }


  @Action(FormAction.ClearFormValue)
  clearData(ctx: StateContext<any>) {
    const defaults = {
      data: null
    }
    ctx.setState(defaults)
  }

}
