import {MbscModule} from '@mobiscroll/angular';
import {NgModule , ɵɵsanitizeStyle} from '@angular/core';
import {BrowserModule ,DomSanitizer} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from "@core/core.module";
import {SmFormValidationModule} from 'sm-ng-form-validation'
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {FacebookconnectModule} from "./modules/facebookconnect/facebookconnect.module";
import {environment} from "@env/environment";
import   { LangService } from "@core/services";
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: 'ios', innerHTMLTemplatesEnabled : true, swipeBackEnabled: false}),
    AppRoutingModule,
    CoreModule,
    SmFormValidationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createtranslateLoader,
        deps: [HttpClient]
      },
      isolate: true
    }),
    FacebookconnectModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
}

export function createtranslateLoader(http: HttpClient) {

  // let url = environment.apiUrl + '/lang/'
  return new TranslateHttpLoader(http, './assets/i18n/'  , '.json');
}
