import {Injectable} from "@angular/core";
import {Storage} from "@ionic/storage-angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})

export class LangService {
    constructor(private storage: Storage,
                private translateService: TranslateService
    ) {
    }

    // get current language
    async getCurrentLang(): Promise<any> {
        const currentLang = await this.getLang();
        if (!currentLang) {
            await this.switchLanguage(this.getUserLocale());
        }
        return this.getLang();
    }

       async  getLang(): Promise<string> {
        return await this.storage.get('lang');
    }

    // switch current language
    async switchLanguage(language: string) {
        return await this.storage.set('lang', language);
    }

    async setCurrrentLangId(id:number) {
      return await this.storage.set('langId', id)
    }

    async getLangId() : Promise<number> {
      return await this.storage.get('langId')
    }

    async clearLangId() : Promise<any> {
      return await  this.storage.remove('langId')
    }

    async clearLang() {
        return await this.storage.remove('lang');
    }

    // get default locale based on browser UI settings
    private getUserLocale(): string {
        const locale = window.navigator.language;
        return locale.split('-')[0];
    }
}
