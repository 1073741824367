import {Injectable} from '@angular/core';
import {ApiService} from "./index";
import {Store} from "@ngxs/store";
import {AppActions} from "@actions/app.action";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private apiService: ApiService,
                private store: Store
    ) {
    }

    // login(params: Object) {
    //     return this.apiService.post('/login/alt', params);
    // }
    //
    // logout() {
    //     return this.apiService.get('/logout');
    // }

    isAuthenticated() {
        this.store.dispatch(new AppActions.SetAuthStatus(false))
    }
}
