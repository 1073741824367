import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, take} from "rxjs";
import {Folder, TemplateData} from "@shared/interface/templates.interface";
import {TemplatesService} from "../../../../features/offer/services/templates.service";
import {ErrorHandlerService} from "@core/services/error-handler.service";
import {HelperService} from "@core/services/helper.service";

import {AppActions} from "@actions/app.action";
import {Select, Store} from "@ngxs/store";
import {TitleAction} from "@actions/title.action";
import {TitleState} from "@states/title.state";
@Component({
  selector: 'profi-templates-folder-list',
  templateUrl: './templates-folder-list.component.html',
  styleUrls: ['./templates-folder-list.component.scss'],
})
export class TemplatesFolderListComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  pageTitle = "Templates";
  recentlyUsedTemplates: TemplateData[] = [];
  isLoading=true;
  isStore:boolean;
  templateFolders: Folder[] = [];
  imageLoaded = false;
  searchString='';
  type =''
  placeholderImageUrl = './assets/images/image89.svg';
  searchFolderTemplate : boolean = true;
  previousUrl = '/dashboard';
  page:number=1;
  last_page:number=0;
  @Select(TitleState.getData) $title: Observable<any>;
  title:any;
  constructor(private router: Router,
              private _activatedRouter: ActivatedRoute,
              private templateService: TemplatesService,
              private errorHandlerService:ErrorHandlerService,
              private store:Store,
              private helperService : HelperService){
  }

  ngOnInit() {
    this.imageLoaded = false;
    // this.getRecentlyUsedTemplates();
    this.getTemplateFolders();
    this.store.dispatch(new AppActions.SetFooterVisibility(true));

  }

  onRouteBack() {
    this.router.navigate(['/dashboard']).then()
  }

  onImageLoad($event: Event) {
    this.imageLoaded = true;
  }
  onScroll() {
    if (this.isNearBottom()) {
      this.loadMore()
    }
  }
  private isNearBottom(): boolean {
    const scrollContainer = this.scrollContainer.nativeElement;
    const scrollHeight = scrollContainer.scrollHeight;
    const scrollTop = scrollContainer.scrollTop;
    const clientHeight = scrollContainer.clientHeight;

    // Adjust the 50 value based on how close to the bottom you want to trigger the API call
    return scrollHeight - (scrollTop + clientHeight) < 50;
  }

  handleResultFolder(event : any) {
     this.templateFolders = event
  }

  getTemplateFolders(){
    this.isLoading = true;
    this.templateService.getFolders(this.page)
      .pipe(
        take(1)
      )
      .subscribe({
        next:(res) => {
          this.templateFolders = res['data']['folders'];
          this.isStore = res['data']['details']['store'];
          this.last_page =res['data']['meta'].last_page;
          this.isLoading = false;

        },
        error : (err) => {
          this.handleError(err);
        }
      })
  }

  loadMore() {
    if (this.page === this.last_page) {
      return;
    }
    this.page++
    this.templateService.getFolders(this.page)
      .pipe(
        take(1)
      )
      .subscribe({
        next:(res) => {
          this.templateFolders = this.templateFolders.concat(res['data']['folders']);
          this.isStore = res['data']['details']['store'];
          this.isLoading = false;

        },
        error : (err) => {
          this.handleError(err);
        }
      })
  }
  search(searchValue:string){
    this.searchString= searchValue;
    this.templateService.searchTemplateList(this.searchString,this.type ,this.page)
      .pipe(
        take(1)
      )
      .subscribe({
      next: (res) => {
        this.type = res['data']['types']
      },
        error: (err) => {
          this.handleError(err);
        }
      })
  }
  onFolderSelect(id: number, name: string) {

    this.store.dispatch(new TitleAction.SetTitleState(name));

    this.router.navigate([`offer/template-list/${id}`]).then()

  }

  onAssignedSelect(name:string) {
    // const encodedName = HelperService.replaceSpace(name);
    this.router.navigate([`/offer/assign-templates`]).then()
  }
  onSavedTemplateSelect(name:string){
    const encodedName = HelperService.replaceSpace(name);
    this.router.navigate([`/offer/saved-templates`]).then()
  }

  recentTemplateSelect(id: number, type : string) {
    this.router.navigate(['/offer/template/' + type + '/' + id]).then()
  }

private handleError(error:Error){
  this.errorHandlerService.handleError(error, '')
}

}
