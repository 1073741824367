export namespace AppActions {
    export class SetFooterVisibility {
        static readonly type = '[App] Set Footer Visibilility';

        constructor(public footerState: boolean) {
        }
    }

    export class SetAuthStatus {
        static readonly type = '[App] Set Authentication Status';

        constructor(public authState: boolean) {
        }

    }

    export class SetFacebookDialogBoxUrl {
        static readonly type = '[App] Set Facebook Dialog Box URL'

        constructor(public url: string) {
        }
    }
   // export class SetUserDetails {
   //    static readonly type = "[App] Set User Detail";
   //
   //   constructor(public userDetail : string) {
   //   }
   // }
}
