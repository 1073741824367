import { Injectable } from '@angular/core';
import {ApiService} from "@core/services";
@Injectable({
  providedIn: 'root'
})

export class OfferServices {
  constructor(private apiService : ApiService) {
  }
  deleteNewsOffer(id:number){
    const url = `/offer-news/${id}`
    return this.apiService.del(url)
  }

  deleteStoreSpecial(id:number){
    const url = `/store-special/${id}`
    return this.apiService.del(url)
  }

  deleteSavedTemplates(id:number) {
    const url = `/template/${id}`

    return this.apiService.del(url)
  }


}

