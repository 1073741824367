import {inject, Injectable} from '@angular/core';
import {ActivatedRoute, CanActivateFn, Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {TokenService} from "@core/services/token.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {

  constructor(private router: Router,
              private store: Store,
              private tokenService: TokenService,
              private _activatetedRoute: ActivatedRoute
  ) {
  }

  async isAuthenticated() {
    //await
    const returnUrl = decodeURIComponent(window.location.pathname);
    const token = await this.tokenService.isTokenActive()
    if (token) {
      return true
    }
    if (returnUrl.includes('/offer/unpublished')) {
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl: 'unpublished'}}).then();
    } else if (returnUrl.includes('/archive')) {
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl: 'archive'}}).then();
    } else {
      this.router.navigate(['/auth/login']).then();
    }
    return false
  }

  async isNotAuthenticated() {
    const token = await this.tokenService.isTokenActive()
    if (!token) {
      return true
    }
    this.router.navigate(['/']).then();
    return false
  }

}

export const IsAuthenticated: CanActivateFn = async () => {
  return await inject(AuthenticationGuard).isAuthenticated();
}

export const IsNotAuthenticated: CanActivateFn = async () => {
  return await inject(AuthenticationGuard).isNotAuthenticated();
}
