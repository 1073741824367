import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient) {
  }

  get(url: any, option?: any): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}${url}`, option)
      .pipe(catchError(this.handleError()));
  }

  post(url: string, params: Object): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}${url}`, params)
      .pipe(catchError(this.handleError()))
  }

  put(url: string, params: Object): Observable<any> {
    return this._http
      .put(`${environment.apiUrl}${url}`, params)
      .pipe(catchError(this.handleError()))
  }

  del(url: string): Observable<any> {
    return this._http
      .delete(`${environment.apiUrl}${url}`)
      .pipe(catchError(this.handleError()))
  }

  getFacebookPageList(token: string): Observable<any> {
    // eslint-disable-next-line max-len
    return this._http.get('https://graph.facebook.com/me/accounts?fields=name,picture.width(200),access_token&access_token=' + token)
      .pipe(
        catchError(this.handleError())
      );
  }

  getFacebookPagesPaginationList(url: string): Observable<any> {
    return this._http.get(url).pipe(
      catchError(this.handleError())
    );
  }


  private handleError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(() => error)
    }
  }
}
