import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, take} from "rxjs";
import {TemplateData} from "@shared/interface/templates.interface";
import {ViewDidEnter} from "@ionic/angular";
import {TemplatesService} from "../../../../features/offer/services/templates.service";
import {ErrorHandlerService} from "@core/services/error-handler.service";
import {AppActions} from "@actions/app.action";
import {Select, Store} from "@ngxs/store";
import {RouteAction} from "@actions/route.action";
import {TranslateService} from "@ngx-translate/core";
import {TitleState} from "@states/title.state";
import {environment} from "@env/environment";

@Component({
  selector: 'profi-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
})
export class TemplatesListComponent implements OnInit {
  pageTitle = "";
  templateList: TemplateData[] = [];
  imageLoaded = false;
  folderType = '';
  sortOptionValue = '';
  placeholderImageUrl = "./assets/images/image89.svg"
  searchString = '';
  folderId: any;
  isTemplateLoading = true;
  page = 1;
  last_page: number = 0;
  previousUrl: string = '/offer/templates'
  @ViewChild('searchBox') searchBox: any;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  isInputfieldClicked = false;
  isSearchClicked = false;
  filterby: string = '';
  showResults = false;
  selectedId = 0;
  types: any = [];
  showOfferOptions = false;
  @Select(TitleState.getData) $title: Observable<any>;
  offerList = [
    {
      "id": 1,
      "name": "_news_",
      "filterBy": "news"
    },
    {
      "id": 2,
      "name": "_offer_",
      "filterBy": "offer"
    },
    {
      "id": 3,
      "name": "_store_special_",
      "filterBy": "store_special"
    }
  ]
  showUnavailableTemplate: boolean = false
  showFilter: boolean = false
  environment = environment;

  constructor(private router: Router,
              private _activatedRoute: ActivatedRoute,
              private templateService: TemplatesService,
              private _store: Store,
              private _translateService: TranslateService,
              private errorHandlerService: ErrorHandlerService) {
  }

  ngOnInit() {
    this.folderType = this._activatedRoute.snapshot.data['assign'];
    this.folderId = this._activatedRoute.snapshot.params['id'];
    this.imageLoaded = false;
    if (this.folderType === 'assigned') {
      this.getAssignedTemplatesList();
      this.pageTitle = this._translateService.instant('_assigned_templates_')
    }
    if (this.folderType === 'templates') {
      this.getPageTitle()
      this.getTemplatesList();
    }
    if (this.folderType == 'saved') {
      this.getSavedTemplatesList();
      this.pageTitle = this._translateService.instant('_saved_templates_')

    }
    this._store.dispatch(new RouteAction.SetPreviousRoute(this.router.url))
    this._store.dispatch(new AppActions.SetFooterVisibility(true));

  }

  getPageTitle() {
    if (this._store.selectSnapshot(TitleState.getData)) {
      this.pageTitle = this._store.selectSnapshot(TitleState.getData)
    } else {
      this.pageTitle = this._translateService.instant('_assigned_templates_')
    }

  }

  showFilterOptions() {
    this.showFilter = !this.showFilter
  }

  hideFilter() {
    this.showFilter = false
  }

  shouldDisplayItem(item: any): boolean {
    if (item.id === 3) {
      return this.environment.showSection;
    }
    return true;
  }

  onTemplateFilter(offer: any) {
    this.selectedId = offer.id
    this.filterby = offer.filterBy
    if (this.folderType === 'saved') {
      this.getsavedSearchedFolderList(this.searchString, 'own')
    }

    if (this.folderType === 'assigned') {
      this.getsavedSearchedFolderList(this.searchString, 'store')
    }
  }


  onRouteBack() {
    this.router.navigate(['/offer/templates'])
  }

  onImageload(event: Event) {
    this.imageLoaded = true;
  }

  onInputClicked() {
    this.isInputfieldClicked = true
  }

  search(searchInput: string) {
    this.isSearchClicked = true;
    this.isInputfieldClicked = false
    this.page = 1
    this.searchString = searchInput;
    if (searchInput !== '' && searchInput.trim().length !== 0) {
      this.loadTemplate()
    }

  }

  private loadTemplate() {
    if (this.folderType === 'templates') {
      this.getSearchTemplateList(this.searchString, this.folderId)
    }
    if (this.folderType === 'saved') {
      this.getsavedSearchedFolderList(this.searchString, 'own')
    }

    if (this.folderType === 'assigned') {
      this.getsavedSearchedFolderList(this.searchString, 'store')
    }
  }

  onClickOutside() {
    this.showFilter = false
    this.filterby = ''
    this.selectedId = 0
    // this.loadTemplate()
  }

  private getSearchTemplateList(searchInput: string, folderId: number) {
    this.isTemplateLoading = true;
    this.templateService.searchFolderTemplateList(searchInput, folderId, this.filterby)
      .pipe(
        take(1)
      ).subscribe({
      next: (res) => {
        // this.showResults = true
        this.isTemplateLoading = false;
        this.templateList = res['data']['templates']
        this.types = res['data']['types'];
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  formatMarkDown(text: string) {
    return text.slice(0, 1).toUpperCase()
  }


  private getsavedSearchedFolderList(searchInput: string, type: string) {
    this.isTemplateLoading = true;
    this.templateService.searchSavedTemplateList(searchInput, type, this.filterby)
      .pipe(
        take(1)
      ).subscribe({
      next: (res) => {
        this.showResults = true
        this.isTemplateLoading = false;
        this.templateList = res['data']['data']
        this.types = res['data']['types'];
        this.isTemplateLoading = false
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  closeTemplate(event: any) {
    this.showUnavailableTemplate = false
  }

  closeSearch() {
    this.searchString = '';
    this.isTemplateLoading = true;
    this.searchBox.nativeElement.value = null;
    this.page = 1
    this.templateList = []
    this.filterby = ''
    this.loadTemplate()
  }

  onScroll() {
    if (this.isNearBottom()) {
      this.loadMore()
    }
  }

  private isNearBottom(): boolean {
    const scrollContainer = this.scrollContainer.nativeElement;
    const scrollHeight = scrollContainer.scrollHeight;
    const scrollTop = scrollContainer.scrollTop;
    const clientHeight = scrollContainer.clientHeight;

    // Adjust the 50 value based on how close to the bottom you want to trigger the API call
    return scrollHeight - (scrollTop + clientHeight) < 50;
  }

  loadMore() {
    if (this.page == this.last_page) {
      return;
    }
    this.page++
    this.loadMoreTemplates();
  }

  loadMoreTemplates() {
    if (this.folderType === 'templates') {
      this.getTemplatesList()
    }
    if (this.folderType === 'assigned') {
      this.getAssignedTemplatesList();
    }
    if (this.folderType === 'saved') {
      this.getSavedTemplatesList()
    }
  }

  getTemplatesList() {
    this.templateService.getTemplateList(this.folderId, this.page, this.filterby)
      .pipe(
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.templateList = this.templateList.concat(res['data'])
          this.last_page = res['meta'].last_page
          this.isTemplateLoading = false;
        },
        error: (err) => {
          this.handleError(err)
        }
      })
  }

  getAssignedTemplatesList() {
    this.templateService.getYourTemplates('store', this.page, this.filterby)
      .pipe(
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.templateList = this.templateList.concat(res['data'])
          this.last_page = res['meta'].last_page
          this.isTemplateLoading = false;
        },
        error: (err) => {
          this.handleError(err);
        }
      })
  }

  getSavedTemplatesList() {
    this.templateService.getYourTemplates('own', this.page, this.filterby)
      .pipe(
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.templateList = this.templateList.concat(res['data'])
          this.last_page = res['meta'].last_page
          this.isTemplateLoading = false;
        },
        error: (err) => {
          this.handleError(err)
        }
      })
  }


  onTemplateSelect(id: number, type: string) {
    this.router.navigate(['/offer/template/' + type + '/' + id], {
      replaceUrl: true
    }).then()

  }

  private handleError(error: Error) {
    this.isTemplateLoading = false;
    this.errorHandlerService.handleError(error, '')
  }
}

