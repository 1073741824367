import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage-angular";
import {ApiService} from "@core/services";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private storage: Storage,
                private apiService: ApiService) {
    }

    login(params: Object) {
        return this.apiService.post('/login/alt', params)
            .pipe(
                map(res => res['data'])
            );
    }

    getLoginUrl() {
      const url = '/azure/login'
      return this.apiService.get(url).pipe(map(res => res['data']['url']))
    }


    logout() {
        return this.apiService.post('/logout', {}).pipe(map(res => res['data']));
    }


}
