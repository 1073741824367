import {State, Action, StateContext, Selector} from "@ngxs/store";
import { TabAction } from "@actions/tab.action";
import {RouteAction} from "@actions/route.action";

@State<any>({
  name: "TabStates",
  defaults: {
    data: null
  }
})

export class TabState {
  @Selector()
  static getData(state: any){
    return state.data
  }

  @Action(TabAction.SetTabState)
  setData(ctx : StateContext<any>,action : TabAction.SetTabState){
    ctx.patchState({data : action.tab})
  }

  @Action(TabAction.ClearTabState)
  clearData(ctx : StateContext<any>) {
    const defaults = {
      data: null
    }
    ctx.setState(defaults)
  }
}
