import {ToastController} from '@ionic/angular';
import {Injectable} from '@angular/core';

const COLOR_MAP = {
    success: 'success',
    info: 'tertiary',
    error: 'danger'
};

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(public toastCtrl: ToastController) {
    }

    async presentToast(message: string, type: 'success' | 'info' | 'error') {

        const toast = await this.toastCtrl.create({
            message: message,
            color: COLOR_MAP[type],
            animated: true,
            duration: 5000,
            position: 'bottom',
            cssClass: 'toast-custom-class',
            buttons: [
                {
                    icon: 'close-outline',
                    role: 'cancel'
                }
            ]
        });
        await toast.present();
    }
}
