import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class HelperService {
  static preventInvalidNumbers(event: any): void {
    const keyPressed = event.key;
    const keyCode = keyPressed.charCodeAt(0);

    if (keyCode === 66 || keyCode === 65) {
      return;
    }
    const regex = /^[0-9.]/;
    if (keyCode === 118) {
      return;
    }
    if (!regex.test(keyPressed)) {
      event.preventDefault();
    }
  }

  static preventInvalidNumbersForQantity(event: any): void {
    const keyPressed = event.key;
    const keyCode = keyPressed.charCodeAt(0);
    if (keyCode === 66 || keyCode === 65) {
      return;
    }
    const regex = /^[0-9]+$/;
    if (keyCode === 118) {
      return;
    }
    if (!regex.test(keyPressed)) {
      event.preventDefault();
    }
  }

 static replaceSpace(name : string):string{
    return name.replace(' ','-')
  }
  static replacePercentage(name :string):string{
    return name.replace(/%20/g, ' ').replace(/%252520/g, ' ');
  }

}
