<ion-header translucent>
  <ion-toolbar *ngIf="showLogin">
    <ion-title>{{'_connect_fb_' | translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">{{'_cancel_' | translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="inner-content">
  <section class="fb-container-skeleton flex-column justify-center align-center" *ngIf="!showLogin && imageLoaded">
    <ion-thumbnail class="fb-image">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <ion-skeleton-text animated class="fb-title"></ion-skeleton-text>
    <ion-skeleton-text animated class="fb-sub-title"></ion-skeleton-text>
  </section>
  <section class="fb-container" *ngIf="showLogin">
    <div class="step fb-confirmation" *ngIf="!pages.length">
      <img class="fb-bg" src="./assets/images/facebook-fan-page@2x.jpg" alt="Facebook Fan Page"
           (load)="imageLoaded = true">
      <div class="message" *ngIf="pageResponse">
        <h2>{{'_hi_' | translate}}</h2>
        <p>{{'_please_connect_facebook_' | translate}}</p>
      </div>
      <div class="message" *ngIf="!pageResponse">
        <h2>{{'_error_' | translate}}</h2>
        <p>{{title | translate}}</p>
      </div>
      <ion-button class="connect ion-padding" expand="full" shape="round" size="medium" (click)="pageLogin()">
        <ion-icon name="logo-facebook" slot="start"></ion-icon>
        {{'_connect_' | translate}}
      </ion-button>
    </div>
    <div class="step fbprofile-list" *ngIf="pages.length">
      <ion-item class="title" lines="none">
        <ion-icon name="logo-facebook" slot="start"></ion-icon>
        <ion-label>{{title | translate}}</ion-label>
      </ion-item>
      <ion-item class="fb-page-list" *ngFor="let page of pages"
                (click)="selectPage(page)" lines="none">
        <ion-thumbnail>
          <ion-img src="{{page.picture.data.url}}" alt="{{page.name}}-page-image"></ion-img>
        </ion-thumbnail>
        <ion-label>
          {{page.name}}
        </ion-label>
      </ion-item>
      <div class="fb-list-nav clearfix">
        <div *ngIf="prevList" (click)="loadPageList('prev')" class="prev"><i
          class="icon-arrow-left"></i>&nbsp;{{'_prev_' | translate}}
        </div>
        <div *ngIf="nextList" (click)="loadPageList('next')" class="next">{{'_next_' | translate}}&nbsp;<i
          class="icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </section>
</div>

