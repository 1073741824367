<div (click)="onClose()" *ngIf="showOfferDetails" class="app-sub-page">
  <div (click)="onPreviewclick($event)" class="preview-section">
    <div class="offer-detail">
      <div class="time-details">
        <div style="display: flex;align-items: center">
          <ion-icon class="timer" name="timer-outline"></ion-icon>
          <p style="margin-left: 10px">{{ formatDatetime(promoOffer.publish_date)  }}</p>
        </div>
        <div (click)="onClose()" class="close-btn">
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      <div class="offer-image">
        <img
          src="{{promoOffer.images[0].url}}">
      </div>

      <div class="offer-title">
        <p>{{promoOffer.title}}</p>
      </div>

      <div class="offer-desp">
        <p>{{promoOffer.text}}</p>
      </div>

      <div class="editbtn">
        <button (click)="onEdit(promoOffer.id, promoOffer.type)" type="button">{{ '_edit_' | translate}}</button>
      </div>
      <div (click)="onDelete(promoOffer.type, promoOffer.id)" class="deletechannelbtn">
        <button type="button">{{"_delete_all_channels_" | translate}}</button>
      </div>

    </div>
  </div>
</div>


