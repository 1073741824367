<ion-app>
  <ion-router-outlet [animated]="false"></ion-router-outlet>

  <footer id="app-footer"
          *ngIf="appState.isAuthenticated && appState.isFooterVisible">
    <ion-tab-bar slot="bottom">
      <ion-tab-button
        [class.activeroute]="dashboard.isActive"
        [routerLink]="['/dashboard']"
        class="tabs"
        (click)="onClearTabState('/dashboard','dashboard')"
        routerLinkActive="ion-tab-button-active"
        #dashboard="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }">
        <ion-icon class = "footer-icon"
          [name]="dashboard.isActive? 'rocket': 'rocket-outline'"></ion-icon>
        <span class="footer-title">{{ '_dashboard_' | translate }}</span>
      </ion-tab-button>
      <ion-tab-button
        class="tabs"
        [class.activeroute]="calendar.isActive"
        [routerLink]="['/calendar']"
        (click)="onClearTabState('/calendar','calendar')"
        routerLinkActive="ion-tab-button-active"
        #calendar="routerLinkActive">
        <ion-icon class="footer-icon"
                  [name]="calendar.isActive? 'calendar' : 'calendar-outline'"></ion-icon>
        <span class="footer-title"> Calendar</span>
      </ion-tab-button>
      <ion-tab-button
        [class.activeroute]="statistics.isActive"
        [routerLink]="['/statistics']"
        (click)="onClearTabState('/statistics','statistics')"
        routerLinkActive="ion-tab-button-active"
        #statistics="routerLinkActive">
        <ion-icon class="footer-icon"
                  [name]="statistics.isActive? 'stats-chart' : 'stats-chart-outline'"></ion-icon>
        <span class="footer-title">{{ '_statistics_' | translate }}</span>
      </ion-tab-button>
      <ion-tab-button
        [class.activeroute]="settings.isActive"
        [routerLink]="['/settings']"
        (click)="onClearTabState('/settings','settings')"
        tab="search"
        routerLinkActive="ion-tab-button-active"
        #settings="routerLinkActive">
        <ion-icon class="footer-icon"
                  [name]="settings.isActive? 'settings' : 'settings-outline'"></ion-icon>
        <span class="footer-title">{{'_setting_' | translate}}</span>
      </ion-tab-button>
    </ion-tab-bar>
  </footer>
</ion-app>
