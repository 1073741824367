import {State, Action, StateContext, Selector} from "@ngxs/store";
import {Injectable} from "@angular/core";
import { RouteAction } from "@actions/route.action";
import {FormAction} from "@actions/form.action";

export interface route {
  url : string
}

@State<any>({
  name: "RouteStates",
  defaults: {
    data: null
  }
})

export class RouteStates {
  @Selector()
  static getData(state : any) {
    return state.data
  }

  @Action(RouteAction.SetPreviousRoute)
  setData(ctx: StateContext<any>, action: RouteAction.SetPreviousRoute) {
    ctx.patchState({data: action.url})
  }

  @Action(RouteAction.ClearRouteData)
  clearData(ctx: StateContext<any>) {
    const defaults = {
      data: null
    }
    ctx.setState(defaults)
  }

}
