import {AppStates} from "@interfaces/app.interface";
import {Injectable} from "@angular/core";
import {Action, State, StateContext,Selector} from "@ngxs/store";
import {AppActions} from "@actions/actions";

@State<AppStates>({
    name: 'AppState',
    defaults: {
        isFooterVisible: false,
        isAuthenticated: false,
        facebookDialogBoxUrl: '',
        // token : ''

    }
})

@Injectable()
export class AppState {
  @Selector([AppState])
  static getData(state:any){
    return state.data
  }
    @Action(AppActions.SetFooterVisibility)
    setFooterState(ctx: StateContext<AppStates>, action: AppActions.SetFooterVisibility) {
        ctx.patchState({isFooterVisible: action.footerState})
    }

    @Action(AppActions.SetAuthStatus)
    setAuthState(ctx: StateContext<AppStates>,
                 action: AppActions.SetAuthStatus) {
        ctx.patchState({isAuthenticated: action.authState})
    }

    @Action(AppActions.SetFacebookDialogBoxUrl)
    setFacebookDialogBoxUrl(ctx: StateContext<AppStates>, action: AppActions.SetFacebookDialogBoxUrl) {
        ctx.patchState({facebookDialogBoxUrl: action.url})
    }
    // @Action(AppActions.SetUserDetails)
    // loginStatus(ctx: StateContext<AppStates>, action: AppActions.SetUserDetails) {
    //   ctx.patchState({ token: action.userDetail });
    // }
}


