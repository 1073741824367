<div #scrollContainer (scroll)="onScroll()" class="app-page page-with-fixed-header">
  <div class="fixed-header">
    <!--    <ion-icon name="arrow-back-outline" class="back-icon" (click)="onRouteBack()"></ion-icon>-->
    <profi-header
      [pageTitle]="pageTitle"
      [previousUrl]="previousUrl"
    >
    </profi-header>
  </div>
  <div class="page-container">
    <div (clickOutside)="onClickOutside()" >
      <div class="searchsection">
        <div class="searchinput">
          <input type="search"
                 [placeholder]=" '_create_from_template_' | translate"
                 #searchBox
                 (focusin)="onInputClicked()"
                 (keyup.enter)="search(searchBox.value)"
                 class="search-input"
          />
          <div *ngIf="searchString" (click)="closeSearch()" class="close-search">
            <ion-icon name="close-circle-outline"></ion-icon>
          </div>
        </div>
        <div class="filter-wrapper">
          <div (click)="search(searchBox.value)" class="searchicon">
            <ion-icon name="search-outline"></ion-icon>
          </div>
          <div (click)="showFilterOptions()" class="filter">
            <ion-icon name="swap-vertical-outline"></ion-icon>
          </div>
        </div>
      </div>

   <div  >
     <div *ngIf="showFilter" class="listsection">
       <div *ngFor="let offer of offerList">
         <div  (click)="onTemplateFilter(offer)" class="list">
           <div class="offer-name">
             {{ offer.name | translate}}
           </div>
           <div>
             <ion-icon *ngIf="selectedId === offer.id" name="radio-button-on-outline"></ion-icon>
             <ion-icon *ngIf="selectedId !== offer.id" name="radio-button-off-outline"></ion-icon>
           </div>
         </div>
       </div>
     </div>
      </div>
      <div class="result-wrapper">
        <h2>{{'_all_templates_' |translate}}</h2>
        <div *ngIf="templateList.length === 0 && !isTemplateLoading">
          <p class="no-post-available">{{'_no_templates_are_available_' | translate}}.</p>
        </div>
        <div *ngIf="!isTemplateLoading">
          <div *ngFor="let templateList of templateList">
            <div (click)="onTemplateSelect(templateList.id, templateList.type)" class="result-list">
              <div style="display: flex">
                <img
                  (load)="onImageload($event)"
                  [src]="imageLoaded ? templateList.image : placeholderImageUrl"
                  class="result-img"
                />
                <div class="result-content">

                  <div style="display: flex;">
                    <div>
                      <span *ngIf="templateList.type === 'store_special'"
                            class="result-title">{{"_store_special_" | translate}}</span>
                      <span *ngIf="templateList.type === 'news' "
                            class="result-title">{{"_template_local_news_" | translate}}</span>
                      <span *ngIf="templateList.type === 'offer' "
                            class="result-title">{{"_template_local_price_" | translate}}</span>
                    </div>
                    <div *ngIf="templateList.gold_id"
                         [class.normal]="templateList.markdown === 'Normal'"
                         [class.rtpu]="templateList.markdown === 'RTPU'"
                         [class.sellout]="templateList.markdown === 'Sellout'"
                         class="gold-id">
                      <strong> {{ formatMarkDown(templateList.markdown) }} </strong>  {{ templateList.gold_id }}
                    </div>
                  </div>


                  <p class="result-subtitle">{{templateList.title}}</p>
                </div>
              </div>
              <div style="width: 24px">
                <ion-icon name="chevron-forward-outline" class="result-right-icon"></ion-icon>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isTemplateLoading ">
          <profi-template-list-skeleton></profi-template-list-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>


<!--<profi-template-unavailable-->
<!--  *ngIf="showUnavailableTemplate"-->
<!--  (closeTemplateInfo)="closeTemplate($event)"-->
<!--&gt;-->

<!--</profi-template-unavailable>-->
