import {State, Action, StateContext, Selector} from "@ngxs/store";
import {UserAction} from "@actions/actions";
import {Injectable} from "@angular/core";
import {UserToken} from "@interfaces/user.interface";

export interface userData {
    token: string
}

export interface userDataState {
    data: userData;
}


@State<UserToken>({
    name: 'userdetail',
    defaults: {
        access_token: '',
        timezone: '',
        lang: '',
        name: ''
    }
})

@Injectable()

export class UserDetails {
    @Selector([UserDetails])
    static getData(state: any) {
        return state.data
    }

    @Action(UserAction.SetUserToken)
    setUserData(ctx: StateContext<UserToken>, action: UserAction.SetUserToken) {
        ctx.patchState({access_token: action.accesstoken})
    }


}
