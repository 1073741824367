<div #scrollContainer (scroll)="onScroll()" class="app-page page-with-fixed-header" >
  <div class="fixed-header">
    <profi-header
      pageTitle="{{ '_templates_'| translate }}"
      [previousUrl]="previousUrl"
    >
    </profi-header>
  </div>
  <div class="page-container">
    <div>
      <profi-search
        [searchFolderTemplate]="searchFolderTemplate"
        (resultFolderEmitter)="handleResultFolder($event)"
      ></profi-search>

      <div>
      </div>
      <h2 style="margin:0">{{'_folders_' |translate}}</h2>
      <div *ngIf = "!isLoading" class="folder-container">
        <div (click)="onSavedTemplateSelect('Saved Templates')" class="folder-content">
          <ion-icon name="folder" class="folder-icon"></ion-icon>
          <p class="content-title">{{"_saved_by_you_" | translate}}</p>
<!--          <p class="content-subtitle">{{"_folder_with_templates_for_you_" |translate}}</p>-->
        </div>
        <div *ngIf="isStore" (click)="onAssignedSelect('Assigned Templates')" class="folder-content">
          <ion-icon name="folder" class="folder-icon"></ion-icon>
          <p class="content-title">{{"_assigned_to_you_" | translate}}</p>
<!--          <p class="content-subtitle">{{"_assigned_folder_with_templates_for_you_" |translate}}</p>-->
        </div>
        <div *ngFor="let templateFolder of templateFolders" class="folder-content">
          <div *ngIf="templateFolders.length !== 0">
            <div (click)="onFolderSelect(templateFolder.id, templateFolder.name)">
              <img
                (load)="onImageLoad($event)"
                [src]="imageLoaded ? templateFolder.image : placeholderImageUrl"
                class="result-img"
              />
              <p class="content-title">{{templateFolder.name}}</p>
              <p class="content-subtitle">{{templateFolder.description}}</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf = "isLoading">
        <profi-templates-folder-list-skeleton></profi-templates-folder-list-skeleton>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
