import {Component, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {AppState} from "@states/app.state";
import {EMPTY, filter, mergeMap, Observable, take} from "rxjs";
import {AppStates} from "@interfaces/app.interface";
import {AppActions} from "@actions/actions";
import {Storage} from "@ionic/storage-angular";
import {TranslateService} from "@ngx-translate/core";
import {LangService, ToastService, UserService} from "@core/services";
import {FacebookeventsService, FacebookService} from "./modules/facebookconnect/services";
import {ModalController} from "@ionic/angular";
import {FacebookmodalComponent} from "./modules/facebookconnect/components";
import {setOptions} from '@mobiscroll/angular';
import {AuthenticationService} from "./pages/authentication/services/authentication.service";
import {UserAction} from "@actions/actions";
import {TokenService} from "@core/services/token.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {TabAction} from "@actions/tab.action";
import {environment} from "@env/environment";

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

@Component({
  selector: 'profi-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(AppState) $appState: Observable<AppStates> | undefined
  appState: AppStates = {
    isAuthenticated: false,
    isFooterVisible: true,
    facebookDialogBoxUrl: 'test',
    // token:''
  };

  defaultLanguage = 'en';
  token = '';
  errorCode: number;
  environment = environment;

  constructor(private store: Store,
              private storage: Storage,
              private translateService: TranslateService,
              private langService: LangService,
              private facebookEventService: FacebookeventsService,
              private facebookService: FacebookService,
              private modalCtrl: ModalController,
              private toastService: ToastService,
              private authService: AuthenticationService,
              private _tokenService: TokenService,
              private _router: Router,
              private _userService: UserService,
              private _toastService: ToastService,
              private _activatedRoute: ActivatedRoute,
              private router: Router
  ) {
    // TODO : Check for auth state

    this.$appState?.subscribe({
      next: (state) => {
        this.appState = state
      }
    })
  }

  async ngOnInit() {
    const activeUrl = decodeURIComponent(window.location.pathname)
    if (activeUrl.includes('/archive')) {
      this.store.dispatch(new TabAction.SetTabState('archived'))
    }
    await this.storage.create();
    this.checkUserDetail().then();
    if (!this.environment.showSection) {
      this.defaultLanguage = 'ro'
    } else {
      this.defaultLanguage = await this.langService.getCurrentLang();
    }
    this.langService.switchLanguage(this.defaultLanguage).then()
    this.translateService.use(this.defaultLanguage)

    // Watch for request to connect to FB
    this.facebookEventService.$connectFacebook
      .pipe(
        mergeMap(() => {
          return this.facebookService.getFacebookDialogURL()
        })
      )
      .subscribe(
        {
          next: (res: any) => {
            let url = res['data']['url']
            if (url) {
              this.showFBDialog(url)
            }
          },
          error: (err) => {
            console.log(err)
          }
        }
      )

  }

  onClearTabState(url: string, tab: string) {
    this.store.dispatch(new TabAction.ClearTabState)
  }

  async showFBDialog(url: string) {
    this.store.dispatch(new AppActions.SetFacebookDialogBoxUrl(url))
    const modal = await this.modalCtrl.create({
      component: FacebookmodalComponent,
      cssClass: 'fb-connect'
    });
    await modal.present();
  }

  async checkUserDetail() {
    const token = await this._tokenService.getUserDetail();
    if (token) {
      this.store.dispatch(new UserAction.SetUserToken(token.access_token));
    }
  }
}
