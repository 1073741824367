export namespace CampaignAction {
  export class SetCampaignState {
    static readonly type = "[SetCampaignState] Set Campaign Data"

    constructor(public tab: any) {
    }
  }

  export class ClearCampaignState {
    static readonly type = "[ClearCampaignState] Clear Campaign Data"
  }
}
