import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import th from "@mobiscroll/angular/dist/js/i18n/th";
import {Store} from "@ngxs/store";
import {TabAction} from "@actions/tab.action";

@Component({
  selector: 'profi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle!: string;
  @Input() previousUrl: string;
  @Input() hideBackButton: boolean = false
  @Input() showPrintIcon: boolean;
  @Input() clearTabState: boolean = false
  @Output() print = new EventEmitter<null>();

  constructor(
    private _router: Router,
    private _location: Location,
    private store: Store
  ) {
  }

  ngOnInit() {
  }

  onPrint() {
    this.print.emit();
  }

  onNavigate() {
    if (this.hasHistory()) {
      this._location.back();
      if (this.clearTabState) {
        this.store.dispatch(new TabAction.ClearTabState());
      }
    } else {
      this._router.navigate(['/dashboard']).then();
    }
  }

  private hasHistory(): boolean {
    return history.length > 1;
  }

}
