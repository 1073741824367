<div class="item-wrapper">
  <div style="display: flex" >
    <ion-skeleton-text class="img-skeleton" animated></ion-skeleton-text>
    <div >
      <ion-skeleton-text class="item-info-wrapper" animated></ion-skeleton-text>
      <ion-skeleton-text class="item-info-title" style="margin-top: 10px" animated></ion-skeleton-text>
    </div>
  </div>
  <ion-skeleton-text class="item-icon" animated></ion-skeleton-text>
</div>
<div class="item-wrapper">
  <div style="display: flex" >
    <ion-skeleton-text class="img-skeleton" animated></ion-skeleton-text>
    <div >
      <ion-skeleton-text class="item-info-wrapper" animated></ion-skeleton-text>
      <ion-skeleton-text class="item-info-title" style="margin-top: 10px" animated></ion-skeleton-text>
    </div>
  </div>
  <ion-skeleton-text class="item-icon" animated></ion-skeleton-text>
</div>
<div class="item-wrapper">
  <div style="display: flex" >
    <ion-skeleton-text class="img-skeleton" animated></ion-skeleton-text>
    <div >
      <ion-skeleton-text class="item-info-wrapper" animated></ion-skeleton-text>
      <ion-skeleton-text class="item-info-title" style="margin-top: 10px" animated></ion-skeleton-text>
    </div>
  </div>
  <ion-skeleton-text class="item-icon" animated></ion-skeleton-text>
</div>
