<div style="border:1px solid #C5C5C7; border-radius: 8px">
   <div class = "result-wrapper">
         <ion-skeleton-text class="result-info-wrapper" animated></ion-skeleton-text>
         <ion-skeleton-text class="result-item-icon" animated></ion-skeleton-text>
   </div>
  <div class="hr"></div>
  <div class="item-wrapper">
    <div style="display: flex" >
      <ion-skeleton-text class="img-skeleton" animated></ion-skeleton-text>
      <div >
        <ion-skeleton-text class="item-info-wrapper" animated></ion-skeleton-text>
        <ion-skeleton-text class="item-info-title" style="margin-top: 10px" animated></ion-skeleton-text>
      </div>
    </div>
    <ion-skeleton-text class="item-icon" animated></ion-skeleton-text>
  </div>
  <div class="item-wrapper">
    <div style="display: flex" >
      <ion-skeleton-text class="img-skeleton" animated></ion-skeleton-text>
      <div >
        <ion-skeleton-text class="item-info-wrapper" animated></ion-skeleton-text>
        <ion-skeleton-text class="item-info-title" style="margin-top: 10px" animated></ion-skeleton-text>
      </div>
    </div>
    <ion-skeleton-text class="item-icon" animated></ion-skeleton-text>
  </div>
</div>

