import {Injectable} from '@angular/core';
import {Storage} from "@ionic/storage-angular";
import {Store} from "@ngxs/store";
import {AppActions} from "@actions/app.action";
import {UserToken} from "@interfaces/user.interface";
import {UserDetail} from "../../../pages/settings/service/setting-resolver.service";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private storage: Storage,
              private appStore: Store
  ) {
  }

  async setUserDetail(userDetail: UserToken) {
    return this.storage.set('userDetail', userDetail);
  }

  async setUserInfo(userInfo: any) {
    return this.storage.set('userInfo', userInfo);
  }

  async setRandomKey() {
    return this.storage.set('test','test')
  }


  async setAccessToken(token: string) {
    return this.storage.set('access_token', token)
  }

  async getToken() {
    return this.storage.get('access_token')
  }

  getUserDetail(): Promise<UserToken> {
    return this.storage.get('userDetail');
  }


  getUserInfo() {
    return this.storage.get('userInfo')
  }

  clearUserInfo() {
    return this.storage.remove('userInfo')
  }

  clearUserDetail(): Promise<any> {
    return this.storage.remove('userDetail')
  }

  clearToken() {
    return this.storage.remove('access_token');
  }

  clearRangeId() {
    return this.storage.remove('rangeId')
  }

  async isTokenActive() {
    const token = await this.getUserDetail();
    // const token = userDetail.access_token;
    if (token) {
      this.appStore.dispatch(new AppActions.SetAuthStatus(true))
      return true
    }
    this.appStore.dispatch(new AppActions.SetAuthStatus(false))
    return false
  }

  async setRangeId(type: number) {
    return this.storage.set('rangeId', type)
  }

  async getRangeId(): Promise<number> {
    return this.storage.get('rangeId')
  }

  async setSelectedTimeZoneId(id: number) {
    return this.storage.set('timezoneid', id)
  }

  async getSelectedTimeZoneId() {
    return this.storage.get('timezoneid')
  }

  async clearTimezoneId() {
    return this.storage.remove('timezoneid')
  }

  async setTimezonelabel(label: string) {
    return this.storage.set('timezonelabel', label)
  }

  async getSelectedZone() {
    return this.storage.get('timezonelabel')
  }
}
