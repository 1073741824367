import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from "@env/environment";
import {Store} from "@ngxs/store";
import {catchError, map} from "rxjs/operators";
import {TokenService} from "@core/services/token.service";
import {ToastService} from "@core/services/toast.service";
import {AppActions} from "@actions/app.action";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})

export class RequestInterceptorService implements HttpInterceptor {
  constructor(
    private _store: Store,
    private _tokenService: TokenService,
    private _toastService: ToastService,
    private _router: Router,
    private _translate: TranslateService
  ) {
  }

  intercept(req: HttpRequest<HttpRequest<any>>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handle(req, next);
  }


  private setRequestHeaders(request: HttpRequest<any>) {
    const headerObject: any = {}
    if (request.url.indexOf(environment.apiUrl) !== -1) {
      const isActive = this._store.snapshot()['AppState']['isAuthenticated'];
      const token = this._store.snapshot()['userdetail']['access_token']
      headerObject['Authorization'] = `Bearer ${token}`;
    }
    const headers = new HttpHeaders(headerObject);
    return request.clone({headers});

  }

  private handle(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(this.setRequestHeaders(req))
      .pipe(
        map((event: HttpEvent<any>) => event),
        catchError((httpError: HttpErrorResponse) => {

          if (httpError.status === 404) {
            return new Observable<HttpEvent<any>>(observer => {

              this._toastService.presentToast(this._translate.instant('_something_went_wrong_'),'error').then(() => {
                this._router.navigate(['/404']).then()
                  observer.error(httpError.error);
                  observer.complete();
              } )
            });
          }

          if (httpError.status === 401) {
            return new Observable<HttpEvent<any>>(observer => {
              this._tokenService.clearUserDetail().then(() => {
                this._store.dispatch(new AppActions.SetAuthStatus(false));
                this._toastService.presentToast('Something went wrong', 'error').then();
                this._router.navigate(['/']).then();
                observer.error(httpError.error);
                observer.complete();
              });
            });
          }
          return throwError(httpError);
        })
      );
  }
}
