import {Injectable} from "@angular/core";
import {ApiService} from "@core/services";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FaceBookPage} from "../interface/facebookconnect.interface";
import {TokenService} from "@core/services/token.service";
import da from "@mobiscroll/angular/dist/js/i18n/da";

@Injectable({
  providedIn: 'root'
})

export class FacebookService {


  constructor(private apiService: ApiService) {
  }

  getFacebookDialogURL(): Observable<string> {
    return this.apiService.get(`/facebook/login-url`).pipe(
    )
  }

  getPageList(token: string): Observable<FaceBookPage[]> {
    return this.apiService.getFacebookPageList(token)
  }

  getPagination(url  :string) : Observable<any> {
    return this.apiService.getFacebookPagesPaginationList(url);
  }

  connectPage(name: string, id: string, token: string, image: string) {
    let data = {
      profile_image: image
    }
    let url = `/facebook/connect?name=${name}&page_id=${id}&access_token=${token}`
    return this.apiService.post(url, data)
  }

  disconnectFbPage() {
    let url = '/facebook/disconnect'
    return this.apiService.post(url, {})
  }

  getFbTokenStatus() {
    let url = '/facebook/access'
    return this.apiService.get(url).pipe(map((res) => res['data']))

  }
}

