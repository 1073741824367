export namespace RouteAction {
  export class SetPreviousRoute {
    static readonly type = "[SetPreviousRoute] Set Route Data"

    constructor(public url: any) {
    }
  }

  export class ClearRouteData {
    static readonly type = "[ClearRouteData] Clear Route Data"
  }
}
