import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {ApiService} from "@core/services";
import {co} from "@fullcalendar/core/internal-common";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private apiService: ApiService) {
  }

  getRecentlyUsed() {
    const url = `/template/recently-used`
    return this.apiService.get(url)
      .pipe(
        map((res) => res['data'])
      );
  }

  getFolders(page: number) {
    const url = `/folders?page=${page}`
    return this.apiService.get(url)
      .pipe(
        map((res) => res)
      )
  }

  getTemplateList(id: number, page: number,filterBy:string) {
    const url = `/folders/${id}?page=${page}&filter=${filterBy}`
    return this.apiService.get(url)
      .pipe(
        map((res) => res['data'])
      )

  }

  getYourTemplates(folderType: string, page: number,filterBy : string) {
    const url = `/folders/${folderType}?page=${page}&filter=${filterBy}`
    return this.apiService.get(url)
      .pipe(
        map((res) => res['data'])
      )

  }

  searchTemplateList(searchString: string, filterBy: string, page: number) {
    const url = `/template/search?search=${searchString}&filter=${filterBy}&page=${page}`
    return this.apiService.get(url)
  }

  searchFolderTemplateList(searchString: string, folderId: number,filterBy:string) {
    const url = `/template/search?folder_id=${folderId}&search=${searchString}&filter=${filterBy}`
    return this.apiService.get(url);
  }

  searchSavedTemplateList(searchString: string, type: string,filterBy? : string) {
    const url = `/folders/${type}?search=${searchString}&filter=${filterBy}`
    return this.apiService.get(url);
  }

  searchFolderList(searchString: string, filterBy: string, page: number) {
    const url = `/folders/search?search=${searchString}&filter=${filterBy}&folder=true&page=${page}`
    return this.apiService.get(url)
  }

  filterTemplate(filterby: string) {
    const url = `/template/search?only=${filterby}`
    return this.apiService.get(url)
  }
}
