<header class="app-header">
  <div class="header-wrapper">
    <ion-icon *ngIf="!hideBackButton" name="arrow-back-outline" class="back-icon"  (click)="onNavigate()" ></ion-icon>
    <div>
      <h1 class="page-title">{{ pageTitle | slice:0:30 }}{{ pageTitle.length > 30 ? '...' : '' }}</h1>
    </div>
    <div (click)="onPrint()" *ngIf="showPrintIcon" class="print">
      <span class="print-text">{{"_print_" |translate}}</span>
      <img src="assets/icon/print.svg">
    </div>
  </div>
</header>
